export const callHotjar = (...args) => {
  if (typeof window.hj !== 'function') {
    console.warn('Hotjar not initialized yet. Arguments will be queued:', args);
    window.hj = function (...hjArgs) {
      (window.hj.q = window.hj.q || []).push(hjArgs);
    };
  }

  window.hj(...args);
};

export const identifyHotjarUser = (userId, properties = {}) => {
  callHotjar('identify', userId || 'anonymous', properties);
};

export const tagHotjarSession = (tags = []) => {
  if (!Array.isArray(tags)) {

    return;
  }
  callHotjar('tagRecording', tags);
};

export const triggerHotjarEvent = (eventName) => {
  if (typeof eventName !== 'string') {

    return;
  }
  callHotjar('trigger', eventName);
};
