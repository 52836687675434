import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { decodePassengers, getProductType, isOldSearchPassengersFormat } from 'utils/Reserbus';
import {
  searchTracker,
  trackTripPriceAlerts,
  viewResultsTracker,
} from 'metrics/user-analytics/search';
import Header from 'components/Header';
import SearchBar from 'components/search/SearchBar';
import Providers from 'components/search/Providers';
import ProviderDepartures from 'components/search/ProviderDepartures';
import ProviderReturns from 'components/search/ProviderReturns';
import LoadingScreen from 'components/LoadingScreen';
import { searchedPassengersCanBeFiltered } from 'utils/passengers';
import { PRICE_ALERT_REDIRECTED } from 'constants/TrackEvents';
import withGrowthBookFeatures from 'components/GrowthBookProvider/withGrowthBookFeatures';
import { identifyHotjarUser } from 'utils/hotjar';
import { getDistinctId } from 'user-analytics';
import Footer from '../../../ui/atoms/Footer';
import LoadingRedirect from '../LoadingRedirect';
import { getMomentFromSearchDateParam } from '../../../utils/search/searchDateParam';
import withWhitelabelTheme from '../../../hocs/whitelabel/withWhitelabelTheme';
import SearchModal from '../../../ui/atoms/SearchModal';

const propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  originSlug: PropTypes.string.isRequired,
  destinationSlug: PropTypes.string.isRequired,
  growthBookFeatures: PropTypes.shape({
    showServiceTypes: PropTypes.bool,
  }).isRequired,
  headerData: PropTypes.shape({
    homeButton: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    dates: PropTypes.string.isRequired,
  }).isRequired,
  isReturns: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  passengers: PropTypes.string.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  searchIsFetching: PropTypes.bool.isRequired,
  searchIsPolling: PropTypes.bool.isRequired,
  analyticsData: PropTypes.object.isRequired,
  setSearchParams: PropTypes.func.isRequired,
  createSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  fetchCoupon: PropTypes.func.isRequired,
  couponLoading: PropTypes.bool,
  couponCode: PropTypes.string,
  couponCodeParam: PropTypes.string,
  searchHasRedirectTrips: PropTypes.bool,
  features: PropTypes.object.isRequired,
  returnIsOpenTicket: PropTypes.bool,
  returnId: PropTypes.string,
  departureId: PropTypes.string,
  returnDidInvalidate: PropTypes.bool,
  departureDidInvalidate: PropTypes.bool,
  setSortOrder: PropTypes.func.isRequired,
  resetPurchase: PropTypes.func.isRequired,
  includeRecommendedTrips: PropTypes.bool,
  resetPayment: PropTypes.func.isRequired,
  setCategoriesFilter: PropTypes.func,
  currentTripList: PropTypes.array,
};

class SearchProcessor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewResultsEventCalled: false,
    };
  }

  componentDidMount() {
    this.addCdsProviderLayoutParam();
    this.handleHotjarIdentification();

    const { setSortOrder, features, resetPurchase, resetPayment, passengers } = this.props;
    resetPurchase();
    resetPayment();
    this.usingNerbyTerminals = new URLSearchParams(window.location.href).get('usingNerbyTerminals');
    this.usingAutoSelectOrigin = new URLSearchParams(window.location.href).get(
      'usingAutoSelectOrigin',
    );

    // Ordering results automatically by price if the feature is enabled
    if (features.SORT_TRIPS_BY_PRICE) {
      setSortOrder('price');
    }

    if (passengers) this.setPassengersFilters({ passengers });
    this.setSearchParams();
    this.createSearch();
  }

  componentWillReceiveProps(nextProps) {
    const { searchIsFetching, searchIsPolling, location } = this.props;
    const { viewResultsEventCalled } = this.state;
    const queryParams = new URLSearchParams(location.search);

    if (searchIsFetching && !nextProps.searchIsFetching) {
      searchTracker(nextProps.analyticsData.search, queryParams);
    }
    if (searchIsPolling && !nextProps.searchIsPolling && !viewResultsEventCalled) {
      const hasRedirectTrips = nextProps.searchHasRedirectTrips;

      const lowestPrice =
        nextProps.currentTripList.length > 0
          ? nextProps.currentTripList.reduce((min, obj) => {
              return obj.pricing.total < min ? obj.pricing.total : min;
            }, nextProps.currentTripList[0].pricing.total)
          : 0;

      this.sendToViewResultsTracker(
        nextProps.analyticsData,
        queryParams,
        hasRedirectTrips,
        lowestPrice,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      growthBookFeatures: { showServiceTypes },
    } = this.props;

    if (prevProps.growthBookFeatures.showServiceTypes !== showServiceTypes) {
      this.addCdsProviderLayoutParam();
      this.handleHotjarIdentification();
    }

    const {
      originSlug,
      destinationSlug,
      departureDate,
      returnDate,
      passengers,
      history: { action },
    } = this.props;
    const originChanged = originSlug !== prevProps.originSlug;
    const destinationChanged = destinationSlug !== prevProps.destinationSlug;
    const departureChanged = departureDate !== prevProps.departureDate;
    const returnChanged = returnDate !== prevProps.returnDate;
    const passengersChanged = passengers !== prevProps.passengers;
    const searchChanged =
      originChanged || destinationChanged || departureChanged || returnChanged || passengersChanged;
    /**
     * If the user changes the search params, we need to create a new search
     * this can happen when the user uses the SearchWidget or the DaysControls.
     * This condition also is true when the user uses the browser's navigation buttons
     *
     */
    if (
      (searchChanged && action === 'POP') ||
      (searchChanged && action === 'PUSH') ||
      (searchChanged && action === 'REPLACE')
    ) {
      // Returning this state to false allows us to track the ViewResults event again
      // for the next search.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ viewResultsEventCalled: false });
      this.setPassengersFilters({ passengers });
      this.setSearchParams();
      this.createSearch();
    }
  }

  componentWillUnmount() {
    const { analyticsData, searchIsFetching, searchIsPolling, location, searchHasRedirectTrips } =
      this.props;
    const { viewResultsEventCalled } = this.state;

    if (searchIsPolling && !searchIsFetching && !viewResultsEventCalled) {
      const queryParams = new URLSearchParams(location.search);
      this.sendToViewResultsTracker(analyticsData, queryParams, searchHasRedirectTrips);
    }
  }

  handleHotjarIdentification() {
    const { growthBookFeatures } = this.props;

    if (growthBookFeatures.showServiceTypes) {
      // Llama al wrapper de Hotjar para identificar al usuario
      const distinctId = getDistinctId();
      identifyHotjarUser(distinctId || 'anonymous', {
        cdsProviderLayout: true,
        abTestGroup: 'B',
      });
    }
  }

  /**
   * Set the categories filters based on the passengers for the search
   * @param {Object} params - function params.
   * @param {String} params.passengers - passengers used for the search
   */
  setPassengersFilters({ passengers }) {
    const { setCategoriesFilter } = this.props;
    const decodedValidPassengersCategories =
      passengers &&
      searchedPassengersCanBeFiltered({ searchPassengers: decodePassengers(passengers) });
    const isOldPassengers = isOldSearchPassengersFormat(passengers);

    if (!decodedValidPassengersCategories || isOldPassengers) return;

    const passengersFilters = passengers.split(',').reduce((acc, passenger) => {
      acc.push(passenger.split('-')[0]);
      return acc;
    }, []);
    setCategoriesFilter({ categories: passengersFilters });
  }

  setSearchParams() {
    const {
      originSlug,
      destinationSlug,
      passengers,
      roundTrip,
      setSearchParams,
      departureDate,
      returnDate,
    } = this.props;
    const departs = getMomentFromSearchDateParam(departureDate).format('DD-MM-YYYY');
    const returns = roundTrip
      ? getMomentFromSearchDateParam(returnDate).format('DD-MM-YYYY')
      : null;

    setSearchParams(originSlug, destinationSlug, decodePassengers(passengers), departs, returns);
  }

  addCdsProviderLayoutParam() {
    const {
      growthBookFeatures: { showServiceTypes },
      location,
      history,
    } = this.props;

    if (showServiceTypes) {
      const queryParams = new URLSearchParams(location.search);

      if (!queryParams.has('cdsProviderLayout')) {
        queryParams.set('cdsProviderLayout', 'true');

        history.replace({
          ...location,
          search: queryParams.toString(),
        });
      }
    }
  }

  sendToViewResultsTracker(analyticsData, queryParams, searchHasRedirectTrips, lowestPrice = '') {
    queryParams.append('usingNerbyTerminals', Boolean(this.usingNerbyTerminals));
    queryParams.append('usingAutoSelectOrigin', Boolean(this.usingAutoSelectOrigin));
    const isTripAlertRedirect = queryParams.get('trip_alert');
    this.setState({ viewResultsEventCalled: true });
    viewResultsTracker(analyticsData, queryParams, searchHasRedirectTrips);
    if (isTripAlertRedirect) {
      trackTripPriceAlerts(PRICE_ALERT_REDIRECTED, analyticsData.search, isTripAlertRedirect, {
        lowestPrice,
      });
    }
  }

  createSearch() {
    const {
      originSlug,
      destinationSlug,
      passengers,
      roundTrip,
      createSearch,
      departureDate,
      returnId,
      departureId,
      returnDate,
      returnDidInvalidate,
      departureDidInvalidate,
      returnIsOpenTicket,
      includeRecommendedTrips,
    } = this.props;
    const departs = getMomentFromSearchDateParam(departureDate).format('DD-MM-YYYY');
    const returns = roundTrip
      ? getMomentFromSearchDateParam(returnDate).format('DD-MM-YYYY')
      : null;

    if (!departureId || departureDidInvalidate) {
      createSearch(
        'departure',
        originSlug,
        destinationSlug,
        departs,
        passengers,
        roundTrip,
        false,
        includeRecommendedTrips,
      );
    }

    if (roundTrip && (!returnId || returnDidInvalidate)) {
      createSearch(
        'return',
        destinationSlug,
        originSlug,
        returns,
        passengers,
        roundTrip,
        returnIsOpenTicket,
        includeRecommendedTrips,
      );
    }
  }

  render() {
    const {
      searchIsFetching,
      headerData,
      isReturns,
      match,
      history,
      fetchCoupon,
      couponLoading,
      couponCode,
      couponCodeParam,
      t,
      features,
    } = this.props;

    if (searchIsFetching) return <LoadingScreen resultMessages />;

    if (!couponCode && !couponLoading && couponCodeParam) {
      fetchCoupon(couponCodeParam, t);
    }

    const isDesktopVersion = getProductType() === 'desktop';
    const queryParams = new URLSearchParams(window.location.search);
    const isExchange = queryParams.get('isExchange');

    const showDesktopsSearchBar = !isExchange && isDesktopVersion;
    const showMobileSearchBar = !isExchange && !isDesktopVersion;

    return (
      <>
        <div className="l-app">
          <Header
            searchFixed
            homeButton={headerData.homeButton}
            backButton={!headerData.homeButton}
            aditionalFixedContent={showDesktopsSearchBar && <SearchBar history={history} />}
            showLogo={!isReturns}
          >
            {showMobileSearchBar ? (
              <SearchModal
                title={t('trips:title_search_modal')}
                originCity={headerData.origin}
                destinationCity={headerData.destination}
                history={history}
              />
            ) : null}
          </Header>

          {features.TRIP_REDIRECTION_LEAD && <LoadingRedirect />}

          <Switch>
            <Route
              path={`${match.path}/:departureId?/:way(departure|return)?/providers`}
              component={Providers}
            />

            <Route
              path={`${match.path}/:way(departures)/:isOpen(open)`}
              component={ProviderDepartures}
            />

            <Route
              path={`${match.path}/:way(departures)/:providerId?`}
              component={ProviderDepartures}
            />

            <Route
              path={`${match.path}/:departureId/:way(returns)/:isOpen(open)`}
              component={ProviderReturns}
            />

            <Route
              path={`${match.path}/:departureId/:way(returns)/:providerId?`}
              component={ProviderReturns}
            />
          </Switch>
        </div>

        {features.SHOW_SEARCH_FOOTER && <Footer />}
      </>
    );
  }
}

SearchProcessor.propTypes = propTypes;
export default withTranslation('trips')(
  withWhitelabelTheme(withGrowthBookFeatures(SearchProcessor, 'show_service_types')),
);
